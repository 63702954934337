import { Button, Form, Input, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'

import { updateProfile } from '@/common/reducers/meReducer'

type Props = {
  user: any
}

const ProfileTab: React.FC<Props> = ({ user }) => {
  const dispatch = useDispatch<any>()
  const [form] = Form.useForm()
  const [disabled, setDisabled] = React.useState(true)
  const [messageApi, contextHolder] = message.useMessage()

  const handleCancel = () => {
    form.setFieldsValue({ name: user.name })
    setDisabled(true)
  }

  const handleSave = async (name: string) => {
    try {
      await dispatch(updateProfile({ name })).unwrap()
      messageApi.success('Profile updated successfully!')
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong!')
      }
    }
  }

  const handleFinish = (values: any) => {
    if (values.name === user.name) {
      setDisabled(true)
      return
    }
    handleSave(values.name)
  }

  const handleValuesChange = (changedValues: any) => {
    const { name } = changedValues
    if (name === user.name) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div className='flex flex-col gap-5 p-6 pb-3'>
      {contextHolder}

      {user && (
        <Form form={form} layout='vertical' onFinish={handleFinish} onValuesChange={handleValuesChange}>
          <Form.Item label='Username (Can not change)' className='mb-3'>
            <Input className='gap-1' value={user.username} disabled />
          </Form.Item>

          <Form.Item label='Email (Can not change)' className='mb-3'>
            <Input className='gap-1' value={user.email} disabled />
          </Form.Item>

          <Form.Item
            label='Display Name'
            name='name'
            className='mb-3'
            initialValue={user.name}
            rules={[{ required: true, message: 'Please input your Your name' }]}
          >
            <Input className='gap-1' />
          </Form.Item>

          <Form.Item>
            <div className='mt-5 flex justify-end gap-5'>
              <Button htmlType='button' onClick={handleCancel}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit' disabled={disabled}>
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </div>
  )
}

export default ProfileTab
