import React from 'react'
import { FacebookEmbed } from 'react-social-media-embed'

const Facebook: React.FC = () => {
  return (
    <div className='center'>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FacebookEmbed
          url='https://www.facebook.com/story.php?story_fbid=970645644856676&id=100057337484068&mibextid=WC7FNe&rdid=CkDWX2Dvpwxz6mzA'
          width={800}
        />
      </div>
    </div>
  )
}

export default Facebook
