// src/pages/CreateDoctorPage.tsx
import { Button, Form, Input, message, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { createDoctor, fetchDepartments } from '../../reducers/doctorReducer'

const { Option } = Select

const AddDoctor: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)

  const handleFinish = async (values: any) => {
    setLoading(true)
    try {
      await dispatch(createDoctor({ ...values, departmentId: Number(values.departmentId) })).unwrap()
      message.success('Bác sĩ đã được tạo thành công')
      form.resetFields()
      setTimeout(() => {
        navigate('/doctors')
      }, 2000)
    } catch (error) {
      message.error('Đã xảy ra lỗi khi tạo bác sĩ')
    } finally {
      setLoading(false)
    }
  }

  const fetchAllDepartments = async () => {
    try {
      const rs = await dispatch(fetchDepartments()).unwrap()
      if (rs) {
        setDepartments(rs.items)
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchAllDepartments()
  }, [])

  return (
    <div className='container mx-auto p-4'>
      <h1 className='mb-4 text-2xl font-bold'>Tạo Bác Sĩ</h1>
      <Form form={form} onFinish={handleFinish} layout='vertical'>
        <Form.Item name='name' label='Tên Bác Sĩ' rules={[{ required: true, message: 'Vui lòng nhập tên bác sĩ' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='username' label='Username' rules={[{ required: true, message: 'Vui lòng nhập Username' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='password' label='Password' rules={[{ required: true, message: 'Vui lòng nhập Password' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item name='specialization' label='Chuyên Khoa'>
          <Input />
        </Form.Item>
        <Form.Item name='phoneNumber' label='Số Điện Thoại'>
          <Input />
        </Form.Item>
        <Form.Item name='email' label='Email' rules={[{ type: 'email', message: 'Email không hợp lệ' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='departmentId' label='Phòng Ban' rules={[{ required: true, message: 'Vui lòng chọn phòng ban' }]}>
          <Select placeholder='Chọn phòng ban'>
            {departments.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            Tạo Bác Sĩ
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AddDoctor
