import React from 'react'

import DoctorManager from './DoctorManager'
import AddDoctor from './partials/create/AddDoctor'

export const DoctorRoutes = [
  {
    path: 'doctors',
    element: <DoctorManager />
  },
  {
    path: 'doctors/add-doctor',
    element: <AddDoctor />
  }
]
