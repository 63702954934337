import { Avatar } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Route, Routes, useLocation } from 'react-router-dom'

import type IUser from '@/common/types/user.type'
import type { RootState } from '@/store'

import PasswordChange from './PasswordChange'
import ProfileTab from './ProfileTab'

const Profile: React.FC = () => {
  const user: IUser = useSelector((state: RootState) => state.auth.user)

  const location = useLocation()

  const getTabClassName = (path: string) => {
    return location.pathname === path
      ? 'inline-block py-2 px-4 text-primary border-b-2 border-primary'
      : 'inline-block py-2 px-4 text-gray-600 hover:text-primary hover:border-primary'
  }

  return (
    <div className='flex h-full justify-center py-3'>
      <div className='flex w-full max-w-none flex-col gap-5 sm:max-w-sm lg:max-w-md'>
        {user && (
          <div className='center'>
            <Avatar className='cursor-pointer bg-primary text-3xl' size={64}>
              {user?.name[0]}
            </Avatar>
          </div>
        )}

        <nav className='flex justify-center gap-5 border-b border-gray-200'>
          <Link to='/profile' className={getTabClassName('/profile')}>
            Profile
          </Link>
          <Link to='/profile/password' className={getTabClassName('/profile/password')}>
            Password
          </Link>
        </nav>

        <Routes>
          <Route path='password' element={<PasswordChange />} />
          <Route path='' element={<ProfileTab user={user} />} />
        </Routes>

        {/* <Tabs defaultActiveKey='1' items={items} /> */}
      </div>
    </div>
  )
}

export default Profile
