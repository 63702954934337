/* eslint-disable react/no-unstable-nested-components */

import { Button, Form, Input, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'

import { changePassword } from '@/common/reducers/meReducer'

const PasswordChange: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const handleSubmit = async (password: string, newPassword: string) => {
    try {
      const result = await dispatch(changePassword({ password, newPassword }))
      if (Object.keys(result.payload).length === 0 || result.payload.statusCode === 200) {
        messageApi.success('Password updated successfully!')
        form.resetFields()
      } else if (result.payload.statusCode === 400) {
        messageApi.error(result.payload.message || 'Something went wrong!')
      }
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong!')
      }
    }
  }

  const onFinish = (values: any) => {
    handleSubmit(values.oldPassword, values.newPassword)
  }

  return (
    <div className='flex flex-col gap-5 p-6 pb-3'>
      {contextHolder}

      <Form layout='vertical' onFinish={onFinish} form={form}>
        <Form.Item label='Old Password' name='oldPassword' rules={[{ required: true, message: 'Please input your Old Password' }]} className='mb-3'>
          <Input type='password' />
        </Form.Item>

        <Form.Item label='New Password' name='newPassword' rules={[{ required: true, message: 'Please input your New Password' }]} className='mb-3'>
          <Input type='password' />
        </Form.Item>

        <Form.Item
          label='Confirm Password'
          name='confirmPassword'
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please input your Confirm Password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords do not match!'))
              }
            })
          ]}
          className='mb-3'
        >
          <Input type='password' />
        </Form.Item>

        <Form.Item>
          <div className='mt-5 flex justify-end gap-5'>
            <Button htmlType='reset'>Cancel</Button>
            <Button type='primary' htmlType='submit'>
              Change
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PasswordChange
